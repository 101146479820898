<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="territory_code">Territory Code</vs-th>
        <vs-th sort-key="territory_desc">Territory Desc</vs-th>
        <vs-th sort-key="sold_to_party">Sold to Party</vs-th>
        <vs-th sort-key="sold_to_party_name">Sold to Party Name</vs-th>
        <vs-th sort-key="sold_to_party_name_2">Sold to Party Name 2</vs-th>
        <vs-th sort-key="dist_channel">Dist Channel</vs-th>
        <vs-th sort-key="dist_channel_desc">Dist Channel Desc</vs-th>
        <vs-th sort-key="cust_grp1">Cust Grp 1</vs-th>
        <vs-th sort-key="cust_grp1_desc">Cust Grp 1 Desc</vs-th>
        <vs-th sort-key="cust_grp2">Cust Grp 2</vs-th>
        <vs-th sort-key="cust_grp2_desc">Cust Grp 2 Desc</vs-th>
        <vs-th sort-key="cust_grp3">Cust Grp 3</vs-th>
        <vs-th sort-key="cust_grp3_desc">Cust Grp 3 Desc</vs-th>
        <vs-th sort-key="cust_grp4">Cust Grp 4</vs-th>
        <vs-th sort-key="cust_grp4_desc">Cust Grp 4 Desc</vs-th>
        <vs-th sort-key="ship_to_party_code">Ship To Party Code</vs-th>
        <vs-th sort-key="ship_to_party_name">Ship To Party Name</vs-th>
        <vs-th sort-key="ship_to_party_name_2">Ship To Party Name 2</vs-th>
        <vs-th sort-key="salesman_code">Salesman Code</vs-th>
        <vs-th sort-key="salesman_name">Salesman Name</vs-th>
        <vs-th sort-key="div_code">Div Code</vs-th>
        <vs-th sort-key="div_desc">Div Desc</vs-th>
        <vs-th sort-key="sku_type">SKU Type</vs-th>
        <vs-th sort-key="material_code">Material Code</vs-th>
        <vs-th sort-key="material_desc">Material Desc</vs-th>
        <vs-th sort-key="order_type">Order Type</vs-th>
        <vs-th sort-key="order_type_desc">Order Type Desc</vs-th>
        <vs-th sort-key="so_number">SO Number</vs-th>
        <vs-th sort-key="so_creation_date">SO Creation Date</vs-th>
        <vs-th sort-key="so_qty">SO Qty</vs-th>
        <vs-th sort-key="so_qty_unit">SO Qty Unit</vs-th>
        <vs-th sort-key="so_amount">SO Amount</vs-th>
        <vs-th sort-key="pi_no">PI No</vs-th>
        <vs-th sort-key="pi_date">PI Date</vs-th>
        <vs-th sort-key="pi_qty">PI Qty</vs-th>
        <vs-th sort-key="pi_unit">PI Unit</vs-th>
        <vs-th sort-key="pi_amount">PI Amount</vs-th>
        <vs-th sort-key="pod_number">POD No</vs-th>
        <vs-th sort-key="pod_confirmation_date">POD Confirmation Date</vs-th>
        <vs-th sort-key="pod_date">POD Date</vs-th>
        <vs-th sort-key="pod_qty">POD Qty</vs-th>
        <vs-th sort-key="pod_unit">POD Unit</vs-th>
        <vs-th sort-key="pod_amount_before_tax">POD Amount (before tax)</vs-th>
        <vs-th sort-key="billing_number">Billing No</vs-th>
        <vs-th sort-key="cancel_billing_status">Cancel Billing Status</vs-th>
        <vs-th sort-key="billing_date">Billing Date</vs-th>
        <vs-th sort-key="billing_creation_time">Bill Creation Time</vs-th>
        <vs-th sort-key="bill_qty">Bill Qty</vs-th>
        <vs-th sort-key="bill_unit">Bill Unit</vs-th>
        <vs-th sort-key="convert_uom">Convert UOM</vs-th>
        <vs-th sort-key="bill_convert_uom_qty">Bill Convert UOM qty</vs-th>
        <vs-th sort-key="hu">HU</vs-th>
        <vs-th sort-key="bill_volume">Bill Volume</vs-th>
        <vs-th sort-key="bill_vol_uom">Bill Volume UOM</vs-th>
        <vs-th sort-key="billing_gross_sales">Billing Gross Sales</vs-th>
        <vs-th sort-key="billing_net_sales">Billing Net Sales</vs-th>
        <vs-th sort-key="unit_price">Unit Price</vs-th>
        <vs-th sort-key="unit_price_per_hu">Unit Price/HU</vs-th>
        <vs-th sort-key="calculated_net_sales">Calculate Net Sales</vs-th>
        <vs-th sort-key="disc_amount_internal">Disc Amount(int)</vs-th>
        <vs-th sort-key="disc_amount_external">Disc Amount(ext)</vs-th>
        <vs-th sort-key="sample_amount_internal">Sample Amount(int)</vs-th>
        <vs-th sort-key="sample_amount_external">Sample Amount(ext)</vs-th>
        <vs-th sort-key="gross_margin">Gross Margin</vs-th>
        <vs-th sort-key="percentage_gross_margin">% Gr Margin</vs-th>
        <vs-th sort-key="gross_up_value">Gross Up Value</vs-th>
        <vs-th sort-key="top_code">TOP Code</vs-th>
        <vs-th sort-key="top_name">TOP Name</vs-th>
        <vs-th sort-key="vat_reg_no">Vat. Reg No</vs-th>
        <vs-th sort-key="item_category">Item Category</vs-th>
        <vs-th sort-key="item_category_desc">Item Category Desc</vs-th>      
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].territory_code">
            {{ data[indextr].territory_code }}
          </vs-td>
          <vs-td :data="data[indextr].territory_code">
            {{ data[indextr].territory_code }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party">
            {{ data[indextr].sold_to_party }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party_name">
            {{ data[indextr].sold_to_party_name }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party_name_2">
            {{ data[indextr].sold_to_party_name_2 }}
          </vs-td>
          <vs-td :data="data[indextr].dist_channel">
            {{ data[indextr].dist_channel }}
          </vs-td>
          <vs-td :data="data[indextr].dist_channel_desc">
            {{ data[indextr].dist_channel_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp1">
            {{ data[indextr].cust_grp1 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp1_desc">
            {{ data[indextr].cust_grp1_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp2">
            {{ data[indextr].cust_grp2 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp2_desc">
            {{ data[indextr].cust_grp2_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp3">
            {{ data[indextr].cust_grp3 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp3_desc">
            {{ data[indextr].cust_grp3_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp4">
            {{ data[indextr].cust_grp4 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp4_desc">
            {{ data[indextr].cust_grp4_desc }}
          </vs-td>
          <vs-td :data="data[indextr].ship_to_party_code">
            {{ data[indextr].ship_to_party_code }}
          </vs-td>
          <vs-td :data="data[indextr].ship_to_party_name">
            {{ data[indextr].ship_to_party_name }}
          </vs-td>
          <vs-td :data="data[indextr].ship_to_party_name_2">
            {{ data[indextr].ship_to_party_name_2 }}
          </vs-td>
          <vs-td :data="data[indextr].salesman_code">
            {{ data[indextr].salesman_code }}
          </vs-td>
          <vs-td :data="data[indextr].salesman_name">
            {{ data[indextr].salesman_name }}
          </vs-td>
          <vs-td :data="data[indextr].div_code">
            {{ data[indextr].div_code }}
          </vs-td>
          <vs-td :data="data[indextr].div_desc">
            {{ data[indextr].div_desc }}
          </vs-td>
          <vs-td :data="data[indextr].sku_type">
            {{ data[indextr].sku_type }}
          </vs-td>
          <vs-td :data="data[indextr].material_code">
            {{ data[indextr].material_code }}
          </vs-td>
          <vs-td :data="data[indextr].material_desc">
            {{ data[indextr].material_desc }}
          </vs-td>
          <vs-td :data="data[indextr].order_type">
            {{ data[indextr].order_type }}
          </vs-td>
          <vs-td :data="data[indextr].order_type_desc">
            {{ data[indextr].order_type_desc }}
          </vs-td>
          <vs-td :data="data[indextr].so_number">
            {{ data[indextr].so_number }}
          </vs-td>
          <vs-td :data="data[indextr].so_creation_date">
            {{ dateFormat(data[indextr].so_creation_date) }}
          </vs-td>
          <vs-td :data="data[indextr].so_qty">
            {{ data[indextr].so_qty }}
          </vs-td>
          <vs-td :data="data[indextr].so_qty_unit">
            {{ data[indextr].so_qty_unit }}
          </vs-td>
          <vs-td :data="data[indextr].so_amount">
            {{ formatPrice(data[indextr].so_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].pi_no">
            {{ data[indextr].pi_no }}
          </vs-td>
          <vs-td :data="data[indextr].pi_date">
            {{ dateFormat(data[indextr].pi_date) }}
          </vs-td>
          <vs-td :data="data[indextr].pi_qty">
            {{ data[indextr].pi_qty }}
          </vs-td>
          <vs-td :data="data[indextr].pi_unit">
            {{ data[indextr].pi_unit }}
          </vs-td>
          <vs-td :data="data[indextr].pi_amount">
            {{ formatPrice(data[indextr].pi_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].pod_number">
            {{ data[indextr].pod_number }}
          </vs-td>
          <vs-td :data="data[indextr].pod_confirmation_date">
            {{ dateFormat(data[indextr].pod_confirmation_date) }}
          </vs-td>
          <vs-td :data="data[indextr].pod_date">
            {{ dateFormat(data[indextr].pod_date) }}
          </vs-td>
          <vs-td :data="data[indextr].pod_qty">
            {{ data[indextr].pod_qty }}
          </vs-td>
          <vs-td :data="data[indextr].pod_unit">
            {{ data[indextr].pod_unit }}
          </vs-td>
          <vs-td :data="data[indextr].pod_amount_before_tax">
            {{ data[indextr].pod_amount_before_tax }}
          </vs-td>
          <vs-td :data="data[indextr].billing_number">
            {{ data[indextr].billing_number }}
          </vs-td>
          <vs-td :data="data[indextr].cancel_billing_status">
            {{ data[indextr].cancel_billing_status }}
          </vs-td>
          <vs-td :data="data[indextr].billing_date">
            {{ dateFormat(data[indextr].billing_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_creation_time">
            {{ data[indextr].billing_creation_time }}
          </vs-td>
          <vs-td :data="data[indextr].bill_qty">
            {{ data[indextr].bill_qty }}
          </vs-td>
          <vs-td :data="data[indextr].bill_unit">
            {{ data[indextr].bill_unit }}
          </vs-td>
          <vs-td :data="data[indextr].convert_uom">
            {{ data[indextr].convert_uom }}
          </vs-td>
          <vs-td :data="data[indextr].bill_convert_uom_qty">
            {{ data[indextr].bill_convert_uom_qty }}
          </vs-td>
          <vs-td :data="data[indextr].hu">
            {{ data[indextr].hu }}
          </vs-td>
          <vs-td :data="data[indextr].bill_volume">
            {{ data[indextr].bill_volume }}
          </vs-td>
          <vs-td :data="data[indextr].bill_vol_uom">
            {{ data[indextr].bill_vol_uom }}
          </vs-td>
          <vs-td :data="data[indextr].billing_gross_sales">
            {{ data[indextr].billing_gross_sales }}
          </vs-td>
          <vs-td :data="data[indextr].billing_net_sales">
            {{ data[indextr].billing_net_sales }}
          </vs-td>
          <vs-td :data="data[indextr].unit_price">
            {{ formatPrice(data[indextr].unit_price) }}
          </vs-td>
          <vs-td :data="data[indextr].unit_price_per_hu">
            {{ formatPrice(data[indextr].unit_price_per_hu) }}
          </vs-td>
          <vs-td :data="data[indextr].calculated_net_sales">
            {{ data[indextr].calculated_net_sales }}
          </vs-td>
          <vs-td :data="data[indextr].disc_amount_internal">
            {{ formatPrice(data[indextr].disc_amount_internal) }}
          </vs-td>
          <vs-td :data="data[indextr].disc_amount_external">
            {{ formatPrice(data[indextr].disc_amount_external) }}
          </vs-td>
          <vs-td :data="data[indextr].sample_amount_internal">
            {{ formatPrice(data[indextr].sample_amount_internal) }}
          </vs-td>
          <vs-td :data="data[indextr].sample_amount_external">
            {{ formatPrice(data[indextr].sample_amount_external) }}
          </vs-td>
          <vs-td :data="data[indextr].gross_margin">
            {{ data[indextr].gross_margin }}
          </vs-td>
          <vs-td :data="data[indextr].percentage_gross_margin">
            {{ data[indextr].percentage_gross_margin }}
          </vs-td>
          <vs-td :data="data[indextr].gross_up_value">
            {{ data[indextr].gross_up_value }}
          </vs-td>
          <vs-td :data="data[indextr].top_code">
            {{ data[indextr].top_code }}
          </vs-td>
          <vs-td :data="data[indextr].top_name">
            {{ data[indextr].top_name }}
          </vs-td>
          <vs-td :data="data[indextr].vat_reg_no">
            {{ data[indextr].vat_reg_no }}
          </vs-td>
          <vs-td :data="data[indextr].item_category">
            {{ data[indextr].item_category }}
          </vs-td>
          <vs-td :data="data[indextr].item_category_desc">
            {{ data[indextr].item_category_desc }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startBillingDate: {
      type: Date,
    },
    endBillingDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportZdrBillModule.tableData,
      total: (state) => state.reportZdrBillModule.total,
      totalPerPage: (state) => state.reportZdrBillModule.totalPerPage,
      totalPage: (state) => state.reportZdrBillModule.totalPage,
      totalSearch: (state) => state.reportZdrBillModule.totalSearch,
      generateReport: (state) => state.reportZdrBillModule.generateReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ 
      getZdrBillReport: 'reportZdrBillModule/getZdrBillReport',
      generateZdrBillReport: 'reportZdrBillModule/generateZdrBillReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'id',
        sort: 'desc',
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.getZdrBillReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_billing_date:
          this.startBillingDate == null
            ? undefined
            : moment(this.startBillingDate).format('YYYY-MM-DD'),
        end_billing_date:
          this.endBillingDate == null
            ? undefined
            : moment(this.endBillingDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
      }).then(() => {
        this.$vs.loading.close();
      });
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport() {
      this.generateZdrBillReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_so_date:
          this.startSoDate == null
            ? undefined
            : moment(this.startSoDate).format('YYYY-MM-DD'),
        end_so_date:
          this.endSoDate == null
            ? undefined
            : moment(this.endSoDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
      })
    },
    formatPrice(val) {
      const formatter = new Intl.NumberFormat('id-ID', {style:'currency', currency:'IDR'})
      return formatter.format(val) 
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    generateReport(val) {
      this.$vs.notify({
        color: 'success',
        title: 'Processing',
        text: val.message,
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-x-circle',
      });
    }
  },
};
</script>
